`<template>
  <div class="card card-custom">
    <b-form inline @submit.stop.prevent="onSubmit" novalidate class="d-block">
      <div class="card-body d-flex flex-column align-items-stretch">
        <div class="b-form-inline-group row">
          <label
            for="input-title"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("COMPANY.TITLE") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-title"
              name="input-title"
              v-model="$v.form.title.$model"
              :state="validateState('title')"
              aria-describedby="input-title-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-title-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="b-form-inline-group row">
          <label
            for="input-email"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("FORM.EMAIL") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-email"
              name="input-email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-email-live-feedback"
            ></b-form-input>
            <b-form-text v-if="isNew" id="email-help-block">
              {{ $t("COMPANY.MAIL_INFO_MESSAGE") }}
            </b-form-text>
            <b-form-invalid-feedback id="input-email-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="b-form-inline-group row" v-if="showPhoneInput">
          <label
            for="input-phone-number"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("FORM.PHONE") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <!-- <b-form-input
              id="input-phone-number"
              name="input-phone-number"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="input-phone-number-live-feedback"
            ></b-form-input> -->
            <PhoneInput
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              :isLoading="countriesLoading"
              :countries="countries"
            />
            <div v-if="$v.form.phone.$error" class="d-flex flex-column">
              <!-- <span
                class="live-feedback-error"
                v-if="!$v.form.phone.phoneNumberFormat"
              >
                Invalid format
              </span> -->
              <span class="live-feedback-error" v-if="!$v.form.phone.required">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </span>
              <span
                class="live-feedback-error"
                v-if="!$v.form.phone.numericPhoneNumber"
              >
                {{ $t("VALIDATION.NUMERIC") }}
              </span>
              <span
                class="live-feedback-error"
                v-if="!$v.form.phone.phoneNumberMinLength"
              >
                {{
                  $t("AUTH.VALIDATION.MIN_LENGTH", {
                    name: $t("FORM.PHONE"),
                    min: $v.form.phone.$params.phoneNumberMinLength.min
                  })
                }}
              </span>
              <span
                class="live-feedback-error"
                v-if="!$v.form.phone.phoneNumberMaxLength"
              >
                {{
                  $t("VALIDATION.MAX_LENGTH", {
                    num: $v.form.phone.$params.phoneNumberMaxLength.max
                  })
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="b-form-inline-group row">
          <label
            for="input-tax-number"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("COMPANY.TAX_NUMBER") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-tax-number"
              name="input-tax-number"
              v-model="$v.form.taxNumber.$model"
              :state="validateState('taxNumber')"
              aria-describedby="input-tax-number-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-tax-number-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="b-form-inline-group row">
          <label
            for="input-tax-administration"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("COMPANY.TAX_ADMINISTRATION") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-tax-administration"
              name="input-tax-administration"
              v-model="$v.form.taxAdministration.$model"
              :state="validateState('taxAdministration')"
              aria-describedby="input-tax-administration-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-tax-administration-live-feedback"
              >{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="b-form-inline-group row">
          <label
            for="input-tax-city"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("COMPANY.TAX_CITY") }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-tax-city"
              name="input-tax-city"
              v-model="$v.form.taxCity.$model"
              :state="validateState('taxCity')"
              aria-describedby="input-tax-city-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-tax-city-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div
          v-if="!isAddingDist && (isSelectedCompanyMerchantOrBranch || isNew)"
          class="b-form-inline-group row"
        >
          <label
            for="input-commission"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
            }}</label
          >
          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-input
              id="input-commission"
              name="input-commission"
              v-model="$v.form.commission.$model"
              :state="validateState('commission')"
              aria-describedby="input-commission-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-commission-live-feedback">{{
              $t("AUTH.VALIDATION.REQUIRED_FIELD")
            }}</b-form-invalid-feedback>
          </div>
        </div>

        <template>
          <div class="form-group row mb-2 align-items-center">
            <label
              class="col-form-label text-right col-lg-3 col-sm-12 align-items-start justify-content-end"
              >{{ $t("FORM.SIGNATURE_AUTH") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12 d-inline-flex">
              <input
                class="col form-control no-border-right"
                :placeholder="$t('FORM.ADD_ROW')"
                disabled
              />
              <div>
                <b-button
                  class="no-border-left"
                  variant="outline-primary"
                  @click="addRowToCompetent"
                  ><b-icon icon="plus" aria-hidden="true"></b-icon
                ></b-button>
              </div>
            </div>
          </div>

          <div
            class="form-group row mb-3"
            v-for="(field, fieldIdx) in $v.form.competent.$each.$iter"
            :key="`competent-model-${fieldIdx}`"
          >
            <div class="col-lg-3 col-sm-12 text-left text-md-right">
              {{ `${parseInt(fieldIdx) + 1}` }}.
            </div>
            <div
              class="col-lg-6 col-md-9 col-sm-12 d-inline-flex pb-3 mb-3"
              style="border-bottom: 1px solid #EBEDF3"
            >
              <div class="row align-items-center flex-wrap mr-1">
                <div class="col-12 col-md-4 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="field.name.$model"
                    :placeholder="$t('FORM.FIRST_NAME')"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="field.name.$error & !field.name.required"
                  >
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </small>
                </div>
                <div class="col-12 col-md-4 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="field.surname.$model"
                    :placeholder="$t('FORM.LAST_NAME')"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="field.surname.$error && !field.surname.required"
                  >
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </small>
                </div>
                <div class="col-12 col-md-4 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="field.identityNumber.$model"
                    :placeholder="$t('FORM.ID_NO')"
                  />
                  <small
                    class="form-text text-danger"
                    v-if="
                      field.identityNumber.$error &&
                        !field.identityNumber.required
                    "
                  >
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </small>
                </div>
                <div class="col-12 col-md-4 mb-2">
                  <input
                    type="email"
                    class="form-control"
                    v-model="field.email.$model"
                    :placeholder="$t('FORM.EMAIL')"
                  />
                  <template v-if="field.email.$error">
                    <small
                      class="form-text text-danger"
                      v-if="!field.email.required"
                    >
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </small>
                    <small
                      class="form-text text-danger"
                      v-if="!field.email.email"
                    >
                      {{ $t("VALIDATION.EMAIL_NOT_VALID") }}
                    </small>
                  </template>
                </div>
                <div class="col-12 col-md-8">
                  <PhoneInput
                    v-model="field.phone.$model"
                    :isLoading="countriesLoading"
                    :countries="countries"
                    :placeholder="$t('FORM.PHONE')"
                  />
                  <div v-if="field.phone.$error" class="d-flex flex-column">
                    <span
                      class="live-feedback-error"
                      v-if="!field.phone.required"
                    >
                      {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                    </span>
                    <span
                      class="live-feedback-error"
                      v-if="!field.phone.numericPhoneNumber"
                    >
                      {{ $t("VALIDATION.NUMERIC") }}
                    </span>
                    <span
                      class="live-feedback-error"
                      v-if="!field.phone.phoneNumberMinLength"
                    >
                      {{
                        $t("AUTH.VALIDATION.MIN_LENGTH", {
                          name: $t("FORM.PHONE"),
                          min: field.phone.$params.phoneNumberMinLength.min
                        })
                      }}
                    </span>
                    <span
                      class="live-feedback-error"
                      v-if="!field.phone.phoneNumberMaxLength"
                    >
                      {{
                        $t("VALIDATION.MAX_LENGTH", {
                          num: field.phone.$params.phoneNumberMaxLength.max
                        })
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="">
                <b-button
                  variant="outline-danger"
                  @click="removeRowToCompetent(fieldIdx)"
                  ><b-icon icon="x" aria-hidden="true"></b-icon
                ></b-button>
              </div>
            </div>
          </div>
          <div class="col-12 text-center" v-if="$v.form.competent.$error">
            <!-- <div
              class="form-text text-danger mb-2"
              v-if="!$v.form.competent.required"
            >
              {{ $t("VALIDATION.REQUIRED_FIELD") }}
            </div> -->
            <div
              class="form-text text-danger mb-2"
              v-if="!$v.form.competent.areEmailsUnique"
            >
              {{ $t("VALIDATION.DUPLICATED_VALUES", { field: "Email" }) }}
            </div>
            <div
              class="form-text text-danger mb-2"
              v-if="!$v.form.competent.arePhonesUnique"
            >
              {{
                $t("VALIDATION.DUPLICATED_VALUES", {
                  field: $t("FORM.PHONE")
                })
              }}
            </div>
            <div
              class="form-text text-danger mb-2"
              v-if="!$v.form.competent.areIdNumUnique"
            >
              {{
                $t("VALIDATION.DUPLICATED_VALUES", {
                  field: $t("FORM.ID_NO")
                })
              }}
            </div>
          </div>
        </template>

        <!-- <div
          v-if="isSelectedCompanyMerchantOrBranch"
          class="b-form-inline-group row"
        >
          <label
            for="input-representative"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
            >{{ $t("COMPANY.SALES_REPRESENTATIVE") }}</label
          >

          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-select
              :disabled="isRepresentativeSelectDisabled"
              v-model="$v.form.representative.$model"
              :options="salesRepresentatives"
              text-field="name"
              value-field="id"
              id="input-representative"
              name="input-representative"
            >
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div> -->

        <!-- <div class="b-form-inline-group row">
          <label
            for="input-pos"
            class="
              col-form-label
              text-right
              col-lg-3 col-sm-12
              align-items-start
              justify-content-end
            "
          >
            {{ $t("PAGES.SALES_MANAGEMENT.POS.POS") }}
          </label>

          <div class="col-lg-6 col-md-9 col-sm-12">
            <b-form-select
              disabled
              v-model="$v.form.pos.$model"
              :options="posList"
              text-field="serial"
              value-field="id"
              id="input-pos"
              name="input-pos"
            >
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div> -->
      </div>
      <div class="d-flex card-footer justify-content-end">
        <button
          type="submit"
          class="btn btn-success"
          data-toggle="modal"
          data-target="#exampleModal"
          :disabled="isSubmittting"
        >
          <b-spinner
            v-if="isSubmittting"
            variant="light"
            small
            type="grow"
            label="Loading..."
          ></b-spinner>
          {{ buttonTitle }}
        </button>
        <b-button
          v-if="!isNew && showDeleteButton"
          class="ml-3"
          :variant="company.isActive ? 'danger' : 'info'"
          @click.stop.prevent="$emit('delete-company')"
        >
          {{
            company.isActive
              ? $t("COMMON.RESTRICT_ACCESS")
              : $t("COMMON.GRANT_ACCESS")
          }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  numeric,
  minValue,
  // requiredIf,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Api from "@/services/api";
import PhoneInput from "@/components/PhoneInput";

const phoneNumberValidation = {
  required: value => {
    const [phoneCode, phoneNumber] = value?.split("-");
    if (!phoneCode || !phoneNumber) {
      return false;
    }
    return true;
  },
  /* phoneNumberFormat(value) {
    const phoneNumber = value?.split("-")[1];
    if (!phoneNumber) return true;
    const regex = /^\+\d{2,}-\d{7,}$/;
    if (!value?.match(regex)) {
      return false;
    }
    return true;
  }, */
  phoneNumberMinLength: value => {
    const phoneNumber = value?.split("-")[1];
    return minLength(7)(phoneNumber);
  },
  phoneNumberMaxLength: value => {
    const phoneNumber = value?.split("-")[1];
    return maxLength(15)(phoneNumber);
  },
  numericPhoneNumber: value => {
    const phoneNumber = value?.split("-")[1];
    return numeric(phoneNumber);
  }
};

export default {
  mixins: [validationMixin],
  props: ["showDeleteButton", "parentId", "isNew", "isSubmittting"],
  components: { PhoneInput },
  data() {
    return {
      company: {},
      salesRepresentatives: [],
      // posList: [],
      form: {
        title: null,
        email: null,
        taxNumber: undefined,
        taxAdministration: undefined,
        taxCity: undefined,
        commission: 3,
        // paymentUnit: null,
        representative: null,
        // pos: null,
        isActive: true,
        phone: null,
        competent: []
      },
      buttonTitle: null
    };
  },
  validations: {
    form: {
      title: { required },
      email: { required },
      taxNumber: { required },
      taxAdministration: { required },
      taxCity: { required },
      commission: {
        // required,
        numeric,
        minValue: minValue(1)
      },
      // paymentUnit: { required },
      representative: {},
      // pos: {},
      phone: phoneNumberValidation,
      competent: {
        $each: {
          name: {
            required
          },
          surname: {
            required
          },
          email: {
            required,
            email
          },
          phone: phoneNumberValidation,
          identityNumber: {
            required
          }
        },
        areEmailsUnique() {
          if (!this.form.competent) {
            return;
          }
          const emails = this.form.competent?.map(item => item.email);
          const isDuplicate = emails.some(
            (item, idx) => emails.indexOf(item) != idx
          );
          return !isDuplicate;
        },
        arePhonesUnique() {
          if (!this.form.competent) {
            return;
          }
          const phones = this.form.competent?.map(item => item.phone);
          const isDuplicate = phones.some(
            (item, idx) => phones.indexOf(item) != idx
          );
          return !isDuplicate;
        },
        areIdNumUnique() {
          if (!this.form.competent) {
            return;
          }
          const identityNumbers = this.form.competent?.map(
            item => item.identityNumber
          );
          const isDuplicate = identityNumbers.some(
            (item, idx) => identityNumbers.indexOf(item) != idx
          );
          return !isDuplicate;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUser: "activeUser",
      selectedCompany: "company/SELECTED_COMPANY",
      selectedCompanyId: "company/SELECTED_COMPANY_ID",
      isSelectedCompanyMerchant: "company/IS_SELECTED_COMPANY_MERCHANT",
      isSelectedCompanyBranch: "company/IS_SELECTED_COMPANY_BRANCH",
      isSelectedCompanyMerchantOrBranch:
        "company/IS_SELECTED_COMPANY_MERCHANT_OR_BRANCH",
      isSelectedCompanyDist: "company/IS_SELECTED_COMPANY_DIST",
      countriesLoading: "mcmCountries/isLoading",
      countries: "mcmCountries/countries"
    }),
    isRepresentativeSelectDisabled() {
      const isNewAndParentMerchant =
        this.isNew && this.selectedCompany.companyRank === "MERCHANT";
      return isNewAndParentMerchant || this.isSelectedCompanyBranch;
    },
    isAddingDist() {
      return (
        this.selectedCompany && Object.keys(this.selectedCompany).length === 0
      );
    },
    showPhoneInput() {
      let show = true;
      /* const companyNotSelected = this.isAddingDist;
      if (companyNotSelected && this.isNew) {
        show = false;
      }
      if (!this.isNew && this.isSelectedCompanyDist) {
        show = false;
      }
      return show; */
      return show;
    }
  },
  watch: {
    selectedCompany(newCompany) {
      if (!this.isNew) {
        this.company = newCompany;
        this.setForm();
      }
    }
  },
  async mounted() {
    this.company = this.isNew ? false : this.selectedCompany;
    this.setForm();
  },
  methods: {
    async setForm() {
      const company = this.company;
      const companyId = company?.id;
      const representativesData = await Api.get("representatives");

      this.salesRepresentatives = representativesData.data.filter(
        representative => representative.isActive
      );

      // const posListUrl = companyId
      //   ? `companies/${companyId}/get-post-list`
      //   : `pos?isActive=true`;

      // const posList = await Api.get(posListUrl);
      // this.posList = posList.data;

      const representativeUri =
        this.isNew && this.selectedCompany.representativeUri;

      if (representativeUri) {
        const representative = await Api.get(representativeUri);
        this.form.representative = representative.data.id;
      } else {
        this.form.representative = null;
      }

      if (companyId) {
        if (company.representativeUri) {
          const representative = await Api.get(company.representativeUri);
          this.form.representative = representative.data.id;
        }

        // if (company.posUri) {
        //   const pos = await Api.get(company.posUri);
        //   this.form.pos = pos.data.id;
        // }

        this.buttonTitle = this.$t("FORM.UPDATE");
        this.form.title = company?.title || null;
        this.form.email = company?.email || null;
        this.form.taxNumber = company?.taxNumber || "";
        this.form.taxAdministration = company?.taxAdministration || "";
        this.form.taxCity = company?.taxCity || "";
        this.form.commission = company?.commission || 0;
        this.form.phone = company?.phone || null;
        this.form.competent = company?.competent || [];
      } else {
        this.buttonTitle = this.$t("FORM.SUBMIT");
      }
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.form.commission = parseFloat(this.form.commission);

      if (this.form.representative) {
        this.form.representative = this.salesRepresentatives.find(
          val => val.id === this.form.representative
        );
      }

      // if (this.form.paymentUnit) {
      //   this.form.paymentUnit = this.paymentUnits.find(
      //     val => val.id === this.form.paymentUnit
      //   );
      // }
      // if (this.form.pos) {
      //   this.form.pos = `/api/pos/${this.form.pos}`;
      // }

      // delete this.form.pos;

      if (this.isNew && this.parentId) {
        this.form["parent"] = `api/companies/${this.parentId}`;
      }

      this.$emit("submit", this.form);
    },
    addRowToCompetent() {
      this.$v.form.competent.$touch();
      if (
        this.form?.competent &&
        this.form?.competent?.length > 0 &&
        this.$v.form.competent.$anyError
      ) {
        return;
      }
      this.$v.form.competent.$reset();
      this.form.competent.push({
        name: null,
        surname: null,
        phone: null,
        identityNumber: null,
        email: null
      });
    },
    removeRowToCompetent(index) {
      if (this.form.competent && this.form.competent.length > 0) {
        this.$v.form.competent.$reset();
        this.form.competent.splice(index, 1);
      }
    }
  }
};
</script>
