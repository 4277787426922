<template>
  <div class="container">
    <div class="card card-custom px-5 py-4">
      <div class="d-flex align-items-center justify-content-between">
        <h2 class="m-0">
          {{ getPageTitle }}
          <span class="ml-4 text-secondary-alt" v-if="!isNewForm"
            >[{{ role }}]</span
          >
        </h2>
        <span v-if="isNewForm" class="text-secondary-alt">
          {{ $t("MENU.NEW") }}
          {{ menuTitle }}
        </span>
      </div>
    </div>
    <template v-if="!isNewForm && selectedCompanyId">
      <div v-if="!isSelectedCompanyBranch" class="d-flex my-5">
        <b-button
          block
          variant="primary"
          :class="formCollapseVisible ? null : 'collapsed'"
          :aria-expanded="formCollapseVisible ? 'true' : 'false'"
          aria-controls="collapse-company-form"
          @click="formCollapseButtonCliced"
        >
          <b-icon
            :icon="formCollapseVisible ? 'chevron-down' : 'chevron-up'"
            aria-hidden="true"
          ></b-icon>
          {{ getInfoAndAffiliateCompaniesTitle.infoTitle }}
        </b-button>

        <b-button
          block
          variant="info"
          class="ml-2 my-0"
          :class="listCollapseVisible ? null : 'collapsed'"
          :aria-expanded="listCollapseVisible ? 'true' : 'false'"
          aria-controls="collapse-company-list"
          @click="listCollapseButtonCliced"
        >
          <b-icon
            :icon="listCollapseVisible ? 'chevron-down' : 'chevron-up'"
            aria-hidden="true"
          ></b-icon>
          {{ getInfoAndAffiliateCompaniesTitle.affiliateCompaniesTitle }}
        </b-button>
      </div>
      <b-collapse id="collapse-company-form" :visible="listVisible">
        <company-form
          :parent-id="parentId"
          :is-new="false"
          v-on:delete-company="deleteCompany"
          :show-delete-button="true"
          v-on:submit="onSubmit"
          :is-submittting="isSubmittting"
        />
      </b-collapse>

      <template v-if="!isSelectedCompanyBranch">
        <b-collapse id="collapse-company-list" v-model="listCollapseVisible">
          <company-list :fixed-filters="fixedFilters" />
        </b-collapse>
      </template>
    </template>

    <company-form
      v-if="isNewForm"
      :parent-id="parentId"
      :is-new="true"
      v-on:delete-company="deleteCompany"
      :show-delete-button="true"
      v-on:submit="onSubmit"
      :is-submittting="isSubmittting"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions, mapGetters } from "vuex";
import Api from "@/services/api";
import { getChildRole } from "@/constants/roles";
import CompanyForm from "@/components/forms/CompanyForm.vue";
import CompanyList from "@/components/list/CompanyList.vue";

export default {
  components: { CompanyForm, CompanyList },
  data() {
    return {
      subTitle: null,
      formCollapseVisible: false,
      listCollapseVisible: true,
      company: {},
      isSubmittting: false
    };
  },
  computed: {
    ...mapGetters({
      selectedCompany: "company/SELECTED_COMPANY",
      selectedCompanyRoleId: "company/SELECTED_COMPANY_ROLE_ID",
      selectedCompanyId: "company/SELECTED_COMPANY_ID",
      isSelectedCompanyBranch: "company/IS_SELECTED_COMPANY_BRANCH",
      isSelectedCompanyMerch: "company/IS_SELECTED_COMPANY_MERCHANT",
      isSelectedCompanyDist: "company/IS_SELECTED_COMPANY_DIST"
    }),
    menuTitle() {
      const child = getChildRole(this.selectedCompanyRoleId);
      const title = this.$t(`PAGES.SALES_MANAGEMENT.LIST.LEVELS["${child}"]`);
      return title;
    },
    role() {
      return this.selectedCompany?.companyRank
        ? this.$t(
            `PAGES.SALES_MANAGEMENT.LIST.LEVELS["${this.selectedCompany?.companyRank}"]`
          )
        : "";
    },
    isNewForm() {
      return this.$route.name === "new-company";
    },
    listVisible() {
      if (this.isSelectedCompanyBranch) {
        return true;
      }
      return this.formCollapseVisible;
    },
    parentId() {
      return this.selectedCompanyId ?? 1;
    },
    submitMessage() {
      const message = this.selectedCompanyId
        ? "FORM.UPDATED"
        : "FORM.SUBMITTED";
      return this.$t(message);
    },
    fixedFilters() {
      return `parent.id=${this.parentId}`;
    },
    getInfoAndAffiliateCompaniesTitle() {
      let infoTitle = this.$t("COMPANY.COMPANY_INFO");
      let affiliateCompaniesTitle = this.$t(
        "PAGES.SALES_MANAGEMENT.LIST.AFFILIATED_COMPANIES"
      );
      if (this.isSelectedCompanyDist) {
        infoTitle = this.$t("COMPANY.dist_info");
        affiliateCompaniesTitle = this.$t("COMPANY.affiliated_merchants");
      }
      if (this.isSelectedCompanyMerch) {
        infoTitle = this.$t("COMPANY.merchant_info");
        affiliateCompaniesTitle = this.$t("COMPANY.affiliated_branches");
      }
      if (this.isSelectedCompanyBranch) {
        infoTitle = this.$t("COMPANY.branch_info");
      }
      return { infoTitle, affiliateCompaniesTitle };
    },
    getPageTitle() {
      let title = this.company?.title;
      if (
        this.selectedCompany &&
        Object.keys(this.selectedCompany).length === 0 &&
        this.isNewForm
      ) {
        title = this.$t("COMPANY.register_new_dist");
      }
      if (this.isSelectedCompanyDist && this.isNewForm) {
        title = this.$t("COMPANY.register_new_merch");
      }
      if (this.isSelectedCompanyMerch && this.isNewForm) {
        title = this.$t("COMPANY.register_new_branch");
      }
      return title;
    }
  },
  watch: {
    selectedCompany(newCompany) {
      this.company = newCompany;
    }
  },
  mounted() {
    this.subTitle = this.selectedCompanyId
      ? this.$t("MENU.EDIT")
      : this.$t("MENU.NEW") + " " + this.$t("COMPANY.COMPANY");

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.MEMBER_MERCHANTS") },
      { title: this.$t("ROUTES.COMPANIES") },
      { title: this.subTitle }
    ]);
    this.company = this.selectedCompany;
    this.$store.dispatch("mcmCountries/GET_COUNTRIES");
  },
  destroyed() {
    this.clearSelectedCompany();
    this.clearParentCompany();
  },
  methods: {
    ...mapActions({
      setParentCompany: "company/SET_PARENT_COMPANY",
      deleteSelectedCompany: "company/DELETE_COMPANY",
      toggleCompanyActiveStatus: "company/TOGGLE_COMPANY_ACTIVE_STATUS",
      clearSelectedCompany: "company/CLEAR_SELECTED_COMPANY",
      clearParentCompany: "company/CLEAR_PARENT_COMPANY"
    }),
    formCollapseButtonCliced() {
      this.listCollapseVisible = false;
      this.formCollapseVisible = !this.formCollapseVisible;
    },
    listCollapseButtonCliced() {
      this.listCollapseVisible = !this.listCollapseVisible;
      this.formCollapseVisible = false;
    },
    onSubmit(company) {
      const apiAction = !this.isNewForm ? Api.put : Api.post;

      let endPoint = "companies";
      endPoint += this.isNewForm ? "" : "/" + this.selectedCompanyId;

      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage = this.$t("COMPANY.COMPANY") + this.submitMessage;
      const errorMessage = this.$t("FORM.ERROR");

      this.isSubmittting = true;
      apiAction(endPoint, company)
        .then(() => {
          this.isSubmittting = false;
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-list" });
        })
        .catch(() => {
          this.isSubmittting = false;
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    deleteCompany() {
      this.toggleCompanyActiveStatus({
        companyId: this.selectedCompanyId,
        isActive: !this.selectedCompany.isActive
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-list" });
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-buttons {
  position: absolute;
}
.collapsed {
  opacity: 0.7;
}
</style>
