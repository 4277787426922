<template>
  <div class="d-flex flex-row nowrap">
    <multiselect
      v-model="getSelectedCountry"
      deselect-label=""
      select-label=""
      selected-label=""
      track-by="calling_code"
      label="calling_code"
      :custom-label="customLabel"
      :show-labels="false"
      placeholder="+XX"
      :options="countries"
      :allow-empty="false"
      open-direction="bottom"
      class="w-25"
      :class="{ 'is-invalid': isInvalid }"
      @change="onChangePhoneNumber"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <div
          class="row align-items-center pl-2 custom-label"
          style="font-size: 1rem !important;"
        >
          <img
            :src="option.flag_png_url"
            style="max-width: 18px; max-height: 18px;"
          />
          <span>{{ option.calling_code }}</span>
        </div>
      </template>
      <template slot="option" slot-scope="{ option }">
        <div
          class="row align-items-center pl-2"
          style="font-size: 1rem !important;"
        >
          <img
            :src="option.flag_png_url"
            style="max-width: 18px; max-height: 18px;"
          />
          <span>{{ option.calling_code }}</span>
        </div>
      </template>

      <template slot="noOptions">{{ "" }}</template>
      <template slot="noResult">{{ "" }}</template>
    </multiselect>
    <!-- <input
      type="text"
      class="form-control phone-input w-75"
      v-model="getPhoneNumber"
      @input="onChangePhoneNumber"
    /> -->
    <b-form-input
      class="form-control phone-input w-75"
      @input="onChangePhoneNumber"
      v-model="getPhoneNumber"
      :state="state"
      :placeholder="placeholder"
    ></b-form-input>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "PhoneInput",
  components: { Multiselect },
  props: {
    value: {
      type: String,
      default: null
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Array,
      default: () => []
    },
    state: {
      type: Boolean,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    getSelectedCountry: {
      get() {
        let country = null;
        const phoneCode = this?.value ? this.value.split("-")[0] : null;
        if (phoneCode && this.countries?.length > 0) {
          country = this.countries.find(
            val => `${val.calling_code}` === phoneCode
          );
        }
        return country;
      },
      set(newValue) {
        this.$emit(
          "input",
          `${newValue.calling_code}-${this.getPhoneNumber || ""}`
        );
      }
    },
    getPhoneNumber: {
      get() {
        return this?.value ? this.value.split("-")[1] : "";
      },
      set(newValue) {
        this.$emit(
          "input",
          `${this?.getSelectedCountry?.calling_code || ""}-${newValue}`
        );
      }
    }
  },
  methods: {
    onChangePhoneNumber() {
      // Add formatting logic here if needed
      // For example, you might want to automatically add spaces or dashes
      // between digits for better readability
    },
    customLabel({ calling_code, name, code }) {
      return `${calling_code}-${name}-${code}`;
    }
  }
};
</script>

<style scoped>
.phone-input {
  line-height: 20px !important;
  min-height: 20px !important;
  height: auto !important;
  font-size: 1rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
::v-deep .multiselect__tags {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 1rem !important;
}
::v-deep .multiselect {
  font-size: 1rem !important;
}
</style>
